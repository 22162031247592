import React, { useEffect, useRef, useState } from "react";
import axios from "../Authorization/client";
import WebSocket from "react-websocket";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router";
import {
  Container,
  ContentWrapper,
  Form,
  FormButton,
  FormContent,
  FormH1,
  FormInput,
  FormLabel,
  FormWrap,
  Loader,
  QrCode,
  QrWrapper,
  Small,
  Text,
  TextLink,
  TextRoute,
  VideoBg,
} from "./SignUpElements";
import { hashEmail, validateUser } from "../GravatarAPI";
import LoadingScreen, { ModalLoadingScreen } from "../LoadingScreen";
import Navbar from "../Navbar";
import { Access } from "../Navbar/Data";
import { signInWithCustomToken } from "@firebase/auth";
import { auth } from "../Authorization/firebase";
import AppBanner from "../AppBanner";
import { XummApp } from "../AppBanner/Data";
import Footer from "../Footer";
import { useModal } from "../Modal/ModalContext";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import { emailRegex } from "../../utils/Regex";
import { VideoUtils } from "../../utils/ImageUtils";
import { getObjectLength } from "../../utils";
import { getSignTxStatus } from "../../utils/dbUtils/signTxUtils";
import { FormButton1, FormText } from "../CustomForm/CustomFormElements";
import { BetaTesterSignInModal } from "../MenuOptions";
import { TRANSACTION_OPTIONS } from "../../utils/Enum";
import { useUser } from "../../hooks/useUser";

const Registration = () => {
  const {video1} = VideoUtils()

  const { toggleModal, modalContent, isOpen, closeModal } = useModal();
  const {validateUser} = useUser({})
  const [email, setEmail] = useState();
  const [invoice, setInvoice] = useState();
  const [status, setStatus] = useState("idle");
  const [response, setResponse] = useState({})
  const [isVLoaded, setVLoaded] = useState(true);
  const [userAddress, setAddress] = useState("");
  const [errMsg, setError] = useState();
  const [emailErr, setEmailError] = useState();
  const [msg, setMessage] = useState("");
  const [isLoaded, setLoaded] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const [payload, setPayload] = useState();
  const [isSigned, setSigned] = useState(false);
  const [next, setNext] = useState(false)
  const [gravatarUrl, setGravatar] = useState();
  const { signup, logout, getGravatarInfo, setEligible, currentUser } =
    useAuth();
  const emailRef = useRef();
  const navigate  = useNavigate();


  useEffect(() => {
    if(invoice){
          getSignTxStatus(invoice, status, setStatus, setResponse)

    }
    return () => {
    }
  }, [invoice])

  useEffect(() => {
    if(invoice){
      if(status === "success" && response !== false){
        VerifySignIn(response)
       
    }if(status === "error"){
      setLoaded(false);
      setPayload();
      setError(
        response?.error ? response?.error : "The sign in has failed. Reload the page and try again"
      );
    }}
    return () => {

    }
  }, [status, invoice])




  function handleInput(e) {
    setError();
    setEmail(e?.target.value)
    setEmailError()
    //emailRef.curr
    //console.log(emailRef.current.value)
    //console.log(hashEmail(e.target.value))
  }

  const updateSignInStatus = async (messageData) => {
    //console.log('Message Data: ', messageData)
    const data = JSON.parse(messageData);
    if (data.opened === true) {
      setOpened(true);
      setMessage("Wating for you to sign transaction");
      return;
    }
    if (isOpened === true && data.signed === true) {
      //VerifySignIn(payload.uuid);
      setLoaded(false)
      setStatus("loading")
      return;
      //console.log("Signed")
    } else if (isOpened === true && data.signed === false) {
      setLoaded(false);
      setPayload({});
      setError(
        "The transaction was rejected. Reload the page and try again"
      );
      return;
    }
    if(data?.expired === true){
      setLoaded(false);
          setPayload();
          setError(
            "The transaction has expired. Reload the page and try again"
          );
      setOpened(false) 
      }
    if(data?.signed === false) {
      setLoaded(false);
      setPayload();
      setError(
        "The transaction was rejected. Reload the page and try again"
      );
      setOpened(false) 
    }
  };

  //Verify xumm payload sign in by user
  //Get users XRP address info (Email Hash and Account Data)
  const VerifySignIn = async (response) => {
    setVLoaded(false);
    setPayload({});
    setError();
    setInvoice()
  setStatus("idle")
  setResponse()
    setOpened(false);
    setLoaded(false);
    setEmailError()
    setEmail()
    
             /*  signInWithCustomToken(auth, response.token)
                .then((user) => {
                      navigate( "/home");
                })
                .catch((err) => {
                  //console.log(err);
                  setEmailError(err.message);
                  setVLoaded(true);
                }); */
               toggleModal()
                modalContent(
                  <OptionModal
                    heading={"Registration Successful"}
                    additionalText={
                      "Your wallet address has successfully signed up for the sonar muse platform. Once we go live, you will be able to access the full platform."
                    }
                  >
                    <HoverOption
                      onClick={() => {
                        navigate( "/");
                        toggleModal();
                        modalContent();
                      }}
                    >
                      OK
                    </HoverOption>
                    <HoverOption
                      onClick={() => {
                        navigate( "/store");
                        toggleModal();
                        modalContent();
                      }}
                    >
                      Music Store
                    </HoverOption>
                    <HoverOption
                      onClick={() => {
                        navigate( "/eligibilityChecker");
                        toggleModal();
                        modalContent();
                      }}
                    >
                      Check Eligibility Status
                    </HoverOption>
                  </OptionModal>
                );
                return;
  
      
  };

  const setAccount = async () => {
   return axios
      .post("/api/userSignUp", {
        email: email,
        userToken: localStorage.getItem("userToken"),
        type: TRANSACTION_OPTIONS.SIGN_UP,
        txInfo: {
          email: email
        },
      })
      .then((res) => {
        setPayload(res.data.payload);
        setInvoice(res.data.invoice)
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        setError("Oops! Something wrong happened. Reload the page");
        setLoaded();
      });
  };


  const validateEmail = async () => {
    toggleModal()
    modalContent(<ModalLoadingScreen text={'Loading. Please Wait'}/>)
    return axios
      .get(`/api/${hashEmail(email)}/validateUser`).then(valid => {
     if(!valid?.data?.exists){
      modalContent(<OptionModal heading={'Continue with Registration?'}
        additionalText={`Clicking continue will direct you to signing a transaction which will link the following email address ${email} to your XRP wallet address.
        In order to access the platform in the future, this email address will have to be linked to the wallet address you sign up with `}>
          <HoverOption onClick={() =>  {
            setAccount()
            setNext(true)
            closeModal()
            modalContent()
            }}>Continue</HoverOption>
        </OptionModal>)
    
     }else{
     setEmail()
     modalContent(<OptionModal heading={'Email Address Already Exists'} additionalText={'An account with this email address already exists. Please enter a different email address or Login'}>
      <HoverOption onClick={() => {
        closeModal()
        modalContent()
        }}>
      OK
        </HoverOption>
         <HoverOption onClick={() => {
        closeModal()
        modalContent()
        }}>
      Login
        </HoverOption>
    
     </OptionModal>)
     }}).catch(err => {
      console.error(err)
      modalContent(<OptionModal heading={'Something went wrong'}>
      <HoverOption onclick={() => {
        validateEmail()}}>Try Again
      </HoverOption>
      </OptionModal>)})

  

  
  
  }

  const validateTx = async() => {
    setLoaded(false)
    return await axios
          .post("/api/verifyTxPayload", { uuid: payload?.uuid }).then((verification) => {
            //console.log(verification)
            if(verification?.data && !verification?.data?.error)
            {
              setLoaded(false);
              //dispatch(verification?.data);
              getSignTxStatus(invoice, status, setStatus, setResponse)
              setSigned(true);
              setPayload();
              setError("The transaction went through successfully.");
              setOpened(false); //console.log("Signed")
              return
            }else{
              setSigned(false);
              setLoaded(true)
              setError("The transaction was not signed.");
              return
            }
          }).catch(err => {
            console.error(err)
            setSigned(false);
            setLoaded(true)
            setError("Something went wrong. Try again");
          }) 
       
  }

  const refreshPage = () => {
    setPayload({});
    setError();
    setInvoice()
  setStatus("idle")
  setResponse()
    setOpened(false);
    setLoaded(false);
    setAccount();
  };

  useEffect(() => {
    if (currentUser?.uid) {
      navigate( "/home");
    } else {
      setAccount();
    }
    setLoaded(false)
    setPayload({})
    setOpened(false)
    setError("");
    return () => {} 
  }, []);

  //render the sign up forms
  return (
    <>
      <Navbar navInfo={Access} />
      <Container>
        <VideoBg autoPlay loop muted src={video1}
                    type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}  >
        </VideoBg>
                  
<AppBanner {...XummApp} />
       <ContentWrapper> 
       {!next && <FormWrap>
                <FormContent>
                  <Form action={(e) => e.preventDefault}>
                    <FormH1> Sign Up </FormH1>
          <FormLabel>Email Address</FormLabel>
          <FormInput onChange={handleInput} placeholder="Email Address" value={email}/>
          {emailErr && <Text>{emailErr}</Text>}
            <Small>Note: This address will be linked to your XRP wallet address</Small>
            <br/>
          <FormButton1 onClick={() => {
            if(email?.trim()?.length > 5 && emailRegex.test(email)){
              return validateEmail()
            }else{
              setEmailError('Invalid email address')
            }
          }}>Continue</FormButton1>

<Small>
                New to web3 or the XRPL? Learn more about how to sign up{" "}
                <TextRoute to="/faq"> here</TextRoute>
              </Small>
              <Small>
                Already have an account?{" "}
                <TextRoute to="#" onClick={() => {
                  toggleModal()
                  modalContent(<BetaTesterSignInModal/>)
                }}>Sign in</TextRoute>
              </Small>
        </Form>
        </FormContent>
        </FormWrap>}
          
       {next && <> {isLoaded === true && getObjectLength(payload) > 0 && (
          <>
            <WebSocket
              url={payload.refs.websocket_status}
              onMessage={(data) => updateSignInStatus(data)}
            />
              <FormWrap>
                <FormContent>
                  <Form action={(e) => e.preventDefault}>
                    <FormH1> Sign Up </FormH1>
                    <QrWrapper href={payload.next.always}>
                      <QrCode src={payload.refs.qr_png} />
                    </QrWrapper>
                    {isOpened === false && (
                      <Text>
                        Scan QR code or click{" "}
                        <TextLink
                          href={payload.next.always}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </TextLink>{" "}
                        to go to your Xumm wallet
                      </Text>
                    )}
                    {isOpened === true && (
                      <Text>
                        <Text>{msg}</Text>
                      </Text>
                    )}
                    <FormButton1 onClick={() => validateTx()} transparent>
                  I Already Signed Transaction
                </FormButton1>
                    <FormButton
                      transparent={false}
                      to="/access/signup"
                      onClick={refreshPage}
                    >
                      Reload
                    </FormButton>
                    <Small>
                New to web3 or the XRPL? Learn more about how to sign up{" "}
                <TextRoute to="/faq"> here</TextRoute>
              </Small>
                    <Small>
                      Already have an account?{" "}
                      <TextRoute onClick={() => {
                        toggleModal()
                        modalContent(<BetaTesterSignInModal/>)
                      }} to="#">Sign in</TextRoute>
                    </Small>{" "} 
                  </Form>
                </FormContent>
              </FormWrap>
           
          </>
        )}

        {errMsg && !response?.error && (
          <FormWrap>
            <FormContent>
              <Form>
                <Text>{errMsg}</Text>
                <FormButton
                  transparent={false}
                  to="/access/signup"
                  onClick={refreshPage}
                >
                  Reload
                </FormButton>
              </Form>
            </FormContent>
          </FormWrap>
        )}

{errMsg && response?.error && (
          <FormWrap>
            <FormContent>
              <Form>
                <Text>{errMsg}</Text>
                <FormButton1
                  transparent={false}
                  to="/access/signup"
                  onClick={() => {
                    setEmail()
                    setEmailError()
                    setNext(false)
                    setLoaded(false)
                    setPayload({});
                    setError();
                    setInvoice()
                  setStatus("idle")
                  setResponse()
                    setOpened(false);
                    setLoaded(false);
                  }}
                >
                  Retry
                </FormButton1>
                <FormButton1 onClick={() => navigate('/')}>
                  Go Home
                </FormButton1>
              </Form>
            </FormContent>
          </FormWrap>
        )}

        {!errMsg && !isLoaded && (
          <LoadingScreen text={"Loading. Please Wait..."} transparent={true} isLoaded={isLoaded} />
        )}</>}
     </ContentWrapper> 
      </Container> 
    </>
  );
};

export default Registration;
