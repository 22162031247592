import React, {useEffect, useState} from 'react'
import { ThemeProvider } from 'styled-components'
import { useAuth } from '../../contexts/AuthContext'
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarLink,
    SidebarMenu,
    SideBtnWrapper,
    SidebarRoute,
    SidebarLinkA
} from './SidebarElements'
import smPdf from '../../files/Sonar_Muse_Whitepaper.pdf'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'
import { BetaTesterSignInModal } from '../MenuOptions'
import { useModal } from '../Modal/ModalContext'

const Sidebar = ({ toggle, isOpen, navInfo }) => {
    const {currentUser, logout} = useAuth()
    const{toggleTheme, theme} = useWebsiteTheme()
    const [scrollNav, setScrollNav] = useState(false);
    const {openModal, closeModal, modalContent} = useModal()

    const changeNav = () => {
      if (window.scrollY >= 80) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    };
  
    const downloadfile = () => {
        toggle()
        window.location.href = smPdf
    }
    
    useEffect(() => {
        window.addEventListener("scroll", changeNav);
        return () => {}
    }, [])
    
    return (
        <SidebarContainer scrollNav={scrollNav} isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLinkA onClick={toggleTheme}>Toggle Theme: {theme === 'light' ? 'Light' : 'Dark'}</SidebarLinkA>
                { navInfo.links && navInfo.links.map((link) => {

                            return (
                                <>
                                {link.id !== 'signout' && !link.file && (link.title !== 'Upload' && link.title !== 'NFT Creator') &&
                                <SidebarLink  key={link.id} id={link.id} to={link?.onClick ? {} : link.to} onClick= {() => {
                                    link?.onClick && link.onClick()
                                    toggle()}} 
                                    isActive={
                                        link?.to ? window.location.pathname.includes(link.to) : window.location.pathname.includes(`#${link?.name?.split(' ').join('%20')}`) 
                                      }
                
                                    >
                                    {link.title}
                                </SidebarLink>}
                                {(currentUser?.uid && (currentUser?.isArtist || currentUser?.isPodcaster || currentUser?.isLabel) ) && (link.title === 'Upload' || link.title === 'NFT Creator') && <SidebarLink key={link.id} id={link.id} to={link.to} onClick= {() => {
                                    link?.onClick && link.onClick()
                                    toggle()}} 
                                    isActive={
                                        link?.to ? window.location.pathname.includes(link.to) : window.location.pathname.includes(`#${link?.name?.split(' ').join('%20')}`) 
                                      }
                                    >
                                    {link.title}
                                </SidebarLink>}
                                {
                                    link.file && 
                                    <SidebarLinkA key={link.id}  onClick={downloadfile} target="_blank" rel="noopener noreferrer">
                                        {link.title}
                                        </SidebarLinkA>
                                }
                                
                                </>
                            )
                        }

                )}
               

                {!currentUser?.uid && 
                <SideBtnWrapper>
                    <SidebarRoute onClick={() => {
                    openModal()
                    modalContent(<BetaTesterSignInModal/>)
                  }}  to='#'> Sign In</SidebarRoute>
                </SideBtnWrapper>
            }
             { currentUser?.uid && localStorage.getItem('user') &&
                <SideBtnWrapper>
                    <SidebarRoute id='signout' to='/' onClick={logout} >
                                Sign Out</SidebarRoute>
                </SideBtnWrapper>
            }
               </SidebarMenu>
               
            </SidebarWrapper>
        </SidebarContainer>
    )
}
export default Sidebar