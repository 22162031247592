import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../images/sonar_muse_logo_nav1.png";
import invertedLogo from "../../images/sonar_muse_logo_nav_invert1.png";
import logo2 from "../../images/sonar_muse_logo_nav2.png";
import invertedLogo2 from "../../images/sonar_muse_logo_nav_invert2.png";
import { Fade } from "react-reveal";

import {
  Nav,
  NavContainer,
  NavLink,
  NavLogo,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  Img,
  NavImg,
  NavLinkA,
  SearchBtn,
  CloseIcon,
  SearchContainer,
  NotificationButton,
  MusicUploadsButton,
  NFTokenCollectionButton,
  PlaylistBtn,
} from "./NavbarElements";
import { useAuth } from "../../contexts/AuthContext";
import smPdf from "../../files/Sonar_Muse_Whitepaper.pdf";
import { useNavigate } from "react-router-dom";
import {BetaTesterSignInModal, NotificationModal } from "../MenuOptions";
import { useUser } from "../../hooks/useUser";
import BadgeIcon from "../BadgeIcon";
import { useNotifications } from "../Notifications/NotificationContext";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import { useModal } from "../Modal/ModalContext";

export const MEDIA_SCREEN = {
  //Screen sizes for devices
  MOBILE: "480",
  TABLETS: "768",
  LAPTOPS: "1024",
  DESKTOPS: "1025",
  TV: "1500",
};

const Navbar = ({ toggleN, toggle, navInfo }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const { currentUser } = useAuth();
  const { themeColors, theme } = useWebsiteTheme();
  const { markAsRead, getNotifications, createLikeNotifications } = useUser({});
  const { modalContent, openModal, closeModal, toggleModal } = useModal();

  const [notificationToggle, setNotificationToggle] = useState(false);
  const { notifications } = useNotifications();
  const navigate = useNavigate();
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {};
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const toggleNotifications = () => {
    let isToggled = notificationToggle;
    setNotificationToggle(!isToggled);
  };

  return (
    <>
      <IconContext.Provider
        value={{
          color: scrollNav ? themeColors.mainBg : themeColors.secondaryBg,
        }}
      >
        <Nav scrollNav={scrollNav}>
          <NavContainer>
            <Fade bottom>
              <NavLogo
                onClick={() =>
                  currentUser?.uid && window.location.href.includes("/edit")
                    ? {}
                    : toggleHome()
                }
                to={
                  currentUser?.uid && window.location.href.includes("/edit")
                    ? "#"
                    : currentUser
                    ? "/home"
                    : "/"
                }
              >
                <NavImg
                  src={
                    scrollNav
                      ? theme === "dark"
                        ? invertedLogo
                        : logo
                      : theme === "dark"
                      ? logo
                      : invertedLogo
                  }
                  alt="Sonar"
                />
                <NavImg
                  src={
                    scrollNav
                      ? theme === "dark"
                        ? invertedLogo2
                        : logo2
                      : theme === "dark"
                      ? logo2
                      : invertedLogo2
                  }
                  alt=" Muse"
                />
              </NavLogo>{" "}
            </Fade>
            {navInfo?.search && (
              <>
                <SearchContainer>
                  <SearchBtn onClick={() => navigate("/search")} />
                  {navInfo?.notifications && (
                    <div style={{ display: "flexbox" }}>
                      {" "}
                      <BadgeIcon
                        onClick={() => {
                          openModal();
                          modalContent(<NotificationModal />);
                        }}
                        icon={
                          notifications &&
                          notifications.length > 0 &&
                          !notifications?.message &&
                          notifications?.length
                        }
                      >
                        <NotificationButton
                          onClick={() => {
                            openModal();
                            modalContent(<NotificationModal />);
                          }}
                        />
                      </BadgeIcon>
                    </div>
                  )}
                </SearchContainer>
              </>
            )}

            {navInfo.bars === true && (
              <Bars scrollNav={scrollNav} onClick={toggle} />
            )}

            <NavMenu>
              {navInfo.links &&
                !navInfo.profile &&
                navInfo.links.map((link) => {
                  if (!link.file) {
                    return (
                      <NavLink
                        scrollNav={scrollNav}
                        key={link.id}
                        id={link.id}
                        to={link.to}
                        isActive={
                          link?.to ? window.location.pathname.includes(link.to) : window.location.pathname.includes(`#${link?.name?.split(' ').join('%20')}`) 
                        }
                      >
                        {link.title}
                      </NavLink>
                    );
                  } else {
                    return (
                      <NavLinkA
                        scrollNav={scrollNav}
                        key={link.id}
                        href={smPdf}
                        download={smPdf}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.title}
                      </NavLinkA>
                    );
                  }
                })}
            </NavMenu>
            {navInfo?.signButton && (
              <NavBtn>
                <NavBtnLink
                onClick={() => {
                  openModal()
                  modalContent(<BetaTesterSignInModal/>)
                }} 
                  to="#"
                  scrollNav={scrollNav}
                  exact="true"
                  offset={-50}
                >
                  Sign In
                </NavBtnLink>
              </NavBtn>
            )}
            {navInfo?.profile && (
              <NavBtn>
                <NavLogo to="#" onClick={toggle} scrollNav={false}>
                  <Img
                    src={navInfo.profile.img}
                    alt={navInfo.profile.alt}
                    height="100%"
                  />
                </NavLogo>
              </NavBtn>
            )}
          </NavContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
